import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Leaderboard } from "../services/types";
import ConfirmDialog from "./ConfirmDialog";

type FormValues = {
  name: string;
};

interface LeaderboardFormProps {
  leaderboard?: Leaderboard;
  onFormSubmit: Function;
  onDeleteSubmit?: Function;
  title: string;
}

export default function CercleForm({
  leaderboard,
  onFormSubmit,
  onDeleteSubmit,
  title,
}: LeaderboardFormProps) {
  const [confirm, setConfirm] = useState(false);

  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: leaderboard,
  });
  const onSubmit = (leaderboard: Leaderboard) => {
    onFormSubmit(leaderboard);
  };

  return (
    <form
      className="space-y-8 divide-y divide-gray-200 px-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="pt-8">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  {...register("name")}
                  id="name"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Tournoi de Noël"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          {onDeleteSubmit && (
            <>
              <button
                type="button"
                onClick={() => setConfirm(!confirm)}
                className="mr-3 bg-red-700 py-2 px-4 border border-red-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete
              </button>
              <ConfirmDialog
                title="Delete Leaderboard"
                isOpen={confirm}
                closeModal={() => setConfirm(false)}
                onConfirm={() => onDeleteSubmit(leaderboard?.id)}
              >
                Are you sure you want to delete this leaderboard? All of your
                data will be permanently removed. This action cannot be undone.
              </ConfirmDialog>
            </>
          )}
          <Link to={`/admin/leaderboards`}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
