import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import LeaderboardForm from "../components/LeaderboardForm";
import LeaderboardService from "../services/leaderboard";
import { Cercle, Leaderboard } from "../services/types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import Spinner from "../components/Spinner";

export default function LeaderboardUpdate() {
  let navigate = useNavigate();
  let { id } = useParams<"id">();
  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    async (cercle: Cercle): Promise<void> => {
      await LeaderboardService.updateCercle(1, cercle);
    },
    {
      onSuccess: async () => {
        toast.success("Leaderboard updated");
        navigate(`/admin/leaderboards`);
      },
      onError: async (err: Error) => {
        toast.error("Error");
        console.log(err);
      },
      onSettled: () => {
        queryClient.invalidateQueries("leaderboards");
      },
    }
  );
  const deleteMutation = useMutation(
    async (id: number): Promise<void> => {
      await LeaderboardService.deleteLeaderboardById(id);
    },
    {
      onSuccess: async () => {
        toast.success("Leaderboard deleted");
        navigate(`/admin/leaderboards`);
      },
      onError: async (err: Error) => {
        console.log(err);
      },
      onSettled: () => {
        queryClient.invalidateQueries("leaderboards");
      },
    }
  );

  const { data: leaderboards, isLoading: isLoading } = useQuery<
    Leaderboard[],
    Error
  >("leaderboards", async () => {
    return await LeaderboardService.findAllLeaderboards();
  });

  if (isLoading || leaderboards === undefined) {
    return <Spinner />;
  }

  const leaderboard = leaderboards.filter((c) => c.id == id)[0];

  return (
    <div>
      <LeaderboardForm
        leaderboard={leaderboard}
        onFormSubmit={updateMutation.mutate}
        onDeleteSubmit={deleteMutation.mutate}
        title="Update Leaderboard"
      />
    </div>
  );
}
