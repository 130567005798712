import { ChangeEvent, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import LeaderboardService from "../services/leaderboard";
import { Cercle, UploadCreated } from "../services/types";
import ConfirmDialog from "./ConfirmDialog";

type FormValues = {
  short_name: string;
  name: string;
  website_url: string;
  logo: string;
};

interface CercleFormProps {
  cercle?: Cercle;
  onFormSubmit: Function;
  onDeleteSubmit?: Function;
  title: string;
}

interface FileProps {
  file: File;
}

const useFileUploadMutation = () => {
  const mutation = useMutation<UploadCreated, Error, FileProps>((args) =>
    LeaderboardService.uploadFile(args.file)
  );
  return mutation;
};

export default function CercleForm({
  cercle,
  onFormSubmit,
  onDeleteSubmit,
  title,
}: CercleFormProps) {
  const fileUploadMutation = useFileUploadMutation();

  const { register, handleSubmit, setValue, getValues } = useForm<FormValues>({
    defaultValues: cercle,
  });
  const onSubmit = (cercle: Cercle) => {
    onFormSubmit(cercle);
  };

  const logoURL = (logoURL: string | undefined | null) => {
    if (logoURL == undefined || logoURL == null || logoURL == "") {
      return process.env.PUBLIC_URL + "/acel.png";
    }
    return logoURL;
  };

  const uploadImage = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const files = event.target.files;
    if (files) {
      const file = files.item(0);
      if (file) {
        fileUploadMutation.mutate(
          { file: file },
          {
            onSuccess: async (res: UploadCreated) => {
              setValue("logo", res.name);
            },
            onError: async (err: Error) => {
              console.log(err);
            },
          }
        );
      }
    }
  };
  const [confirm, setConfirm] = useState(false);

  return (
    <form
      className="space-y-8 divide-y divide-gray-200 px-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="pt-8">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="short_name"
                className="block text-sm font-medium text-gray-700"
              >
                Short name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="short_name"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="ACEL"
                  {...register("short_name")}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  {...register("name")}
                  id="name"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Association des Cercles d’Étudiants Luxembourgeois"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="website_url"
                className="block text-sm font-medium text-gray-700"
              >
                Website
              </label>
              <div className="mt-1">
                <input
                  id="website_url"
                  type="text"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register("website_url")}
                />
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="file-upload"
                className="cursor-pointer block text-sm font-medium text-gray-700"
              >
                <span className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  Upload logo
                </span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onChange={uploadImage}
                />
              </label>
              <img
                className="h-24 w-24 object-contain border-2 m-2"
                src={logoURL(getValues("logo"))}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          {onDeleteSubmit && (
            <>
              <button
                type="button"
                onClick={() => setConfirm(!confirm)}
                className="mr-3 bg-red-700 py-2 px-4 border border-red-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete
              </button>
              <ConfirmDialog
                title="Delete Cercle"
                isOpen={confirm}
                closeModal={() => setConfirm(false)}
                onConfirm={() => onDeleteSubmit(cercle?.id)}
              >
                Are you sure you want to delete this cercle? All of your data
                will be permanently removed. This action cannot be undone.
              </ConfirmDialog>
            </>
          )}
          <Link to={`/admin/cercles`}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
