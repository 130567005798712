import { ChangeEvent } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { User } from "../services/types";

type FormValues = {
  email: string;
  is_superuser: boolean;
  password: string;
};

interface UserFormProps {
  user: User;
  onFormSubmit: Function;
}

export default function CercleForm({ user, onFormSubmit }: UserFormProps) {
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: user,
  });
  const onSubmit = (user: User) => {
    onFormSubmit(user);
  };

  return (
    <form
      className="space-y-8 divide-y divide-gray-200 px-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="pt-8">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              User
            </h3>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  id="email"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="admin@acel.lu"
                  {...register("email")}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="is_superuser"
                className="block text-sm font-medium text-gray-700"
              >
                Has Access
              </label>
              <div className="mt-1">
                <input
                  id="is_superuser"
                  type="checkbox"
                  {...register("is_superuser")}
                  className="focus:ring-blue-500 h-6 w-6 text-blue-600 border-gray-300 rounded"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Change Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  type="password"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register("password")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          {/* {onDeleteSubmit && (
            <Link to={`/admin/users`}>
              <button
                type="button"
                onClick={() => onDeleteSubmit(cercle?.id)}
                className="mr-3 bg-red-700 py-2 px-4 border border-red-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete
              </button>
            </Link>
          )} */}
          <Link to={`/admin/users`}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
