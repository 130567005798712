import {
  ChartBarIcon,
  UserGroupIcon,
  LogoutIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { Outlet, useLocation } from "react-router";
import { Link } from "react-router-dom";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

const navigation = [
  {
    name: "Leaderboards",
    href: "/admin/leaderboards",
    icon: ChartBarIcon,
    active: "leaderboard"
  },
  {
    name: "Cercles",
    href: "/admin/cercles",
    icon: UserGroupIcon,
    active: "cercle"
  },
  {
    name: "Users",
    href: "/admin/users",
    icon: UserIcon,
    active: "user"
  },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function AdminLayout() {
  let location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    localStorage.removeItem("token");
    queryClient.clear();
    navigate("/login");
  };

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        {/* Static sidebar for desktop */}
        <div className="lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
          <div className="flex flex-col flex-grow bg-blue-700 pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <p className="text-white font-medium text-2xl">Backend</p>
            </div>
            <nav
              className="mt-5 flex-1 flex flex-col divide-y divide-blue-800 overflow-y-auto"
              aria-label="Sidebar"
            >
              <div className="px-2 space-y-1">
                {navigation.map((item) => (
                  <Link to={`${item.href}`}>
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        location.pathname.includes(item.active)
                          ? "bg-blue-800 text-white"
                          : "text-blue-100 hover:text-white hover:bg-blue-600",
                        "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className="mr-4 flex-shrink-0 h-6 w-6 text-blue-200"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  </Link>
                ))}
                <a
                  onClick={handleLogout}
                  href="#"
                  className="text-blue-100 hover:text-white hover:bg-blue-600 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                >
                  <LogoutIcon
                    className="mr-4 flex-shrink-0 h-6 w-6 text-blue-200"
                    aria-hidden="true"
                  />
                  Logout
                </a>
              </div>
            </nav>
          </div>
        </div>

        <div className="flex flex-col flex-1">
          <main className="flex-1 pt-10 lg:ml-64 max-w-5xl py-10">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}
