import { useQuery } from "react-query";
import LeaderboardService from "../services/leaderboard";
import { Leaderboard } from "../services/types";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import { PlusIcon } from "@heroicons/react/solid";
import Spinner from "../components/Spinner";

function Leaderboards() {
  const { data: leaderboards, isLoading: isLoading } = useQuery<
    Leaderboard[],
    Error
  >("leaderboards", async () => {
    return await LeaderboardService.findAllLeaderboards();
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="pb-4">
              <Link to={`/admin/leaderboard`}>
                <a href={`/admin/leaderboard`}>
                  <Button>
                    <PlusIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Add Leaderboard
                  </Button>
                </a>
              </Link>
            </div>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>

                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {leaderboards?.map(({ name, id }, index) => (
                    <tr
                      key={id}
                      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link to={`/admin/leaderboard/${id}`}>
                          <a
                            href={`/admin/leaderboard/${id}`}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            Edit
                          </a>
                        </Link>
                        <span> | </span>
                        <Link to={`/admin/leaderboard/${id}/scores`}>
                          <a
                            href={`/admin/leaderboard/${id}/scores`}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            Scores
                          </a>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Leaderboards;
