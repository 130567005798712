import React from "react";
import "./index.css";
import Leaderboard from "./pages/Leaderboard";
import reportWebVitals from "./reportWebVitals";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Cercles from "./pages/Cercles";
import AdminLayout from "./layout/AdminLayout";
import Leaderboards from "./pages/Leaderboards";
import { QueryClient, QueryClientProvider } from "react-query";
import CercleUpdate from "./pages/CercleUpdate";
import CercleCreate from "./pages/CercleCreate";
import LeaderboardCreate from "./pages/LeaderboardCreate";
import LeaderboardUpdate from "./pages/LeaderboardUpdate";
import LeaderboardScores from "./pages/LeaderboardScores";
import { ReactQueryDevtools } from "react-query/devtools";
import Users from "./pages/Users";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import UserUpdate from "./pages/UserUpdate";

const rootElement = document.getElementById("root");
const queryClient = new QueryClient();

const isTokenValid = (token: string) => {
  let isJwtExpired = false;
  try {
    const decoded: JwtPayload = jwt_decode(token);
    const currentTime = new Date().getTime() / 1000;
    const expiryTime = decoded.exp;

    if (expiryTime && currentTime > expiryTime) {
      isJwtExpired = true;
    }
  } catch (error) {
    isJwtExpired = true;
  }

  return !isJwtExpired;
};

export const RequireAuth: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const authed = isTokenValid(localStorage.getItem("token") || "");
  console.log(localStorage.getItem("token"));
  return authed === true ? children : <Navigate to="/login" replace />;
};

render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Leaderboard />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="admin"
            element={
              <RequireAuth>
                <AdminLayout />
              </RequireAuth>
            }
          >
            <Route path="users" element={<Users />} />
            <Route path="user/:id" element={<UserUpdate />} />
            <Route index element={<Leaderboards />} />
            <Route path="cercles" element={<Cercles />} />
            <Route path="cercle/:id" element={<CercleUpdate />} />
            <Route path="cercle" element={<CercleCreate />} />
            <Route path="leaderboards" element={<Leaderboards />} />
            <Route path="leaderboard" element={<LeaderboardCreate />} />
            <Route path="leaderboard/:id" element={<LeaderboardUpdate />} />
            <Route
              path="leaderboard/:id/scores"
              element={<LeaderboardScores />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
