import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import CercleForm from "../components/CercleForm";
import LeaderboardService from "../services/leaderboard";
import { Cercle } from "../services/types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";

export default function CercleUpdate() {
  let navigate = useNavigate();
  let { id } = useParams<"id">();

  const updateMutation = useMutation(
    async (cercle: Cercle): Promise<void> => {
      await LeaderboardService.updateCercle(Number(id), cercle);
    },
    {
      onSuccess: async () => {
        toast.success("Cercle Updated");
        navigate(`/admin/cercles`);
      },
      onError: async (err: Error) => {
        toast.error("Error");
        console.log(err);
      },
    }
  );

  const deleteMutation = useMutation(
    async (id: number): Promise<void> => {
      await LeaderboardService.deleteCercleById(id);
    },
    {
      onSuccess: async () => {
        navigate(`/admin/cercles`);
      },
      onError: async (err: Error) => {
        console.log(err);
      },
    }
  );

  const { data: cercles, isLoading: isLoading } = useQuery<Cercle[], Error>(
    "cercles",
    async () => {
      return await LeaderboardService.findAllCerles();
    }
  );

  if (isLoading || cercles === undefined) {
    return <Spinner />;
  }

  const cercle = cercles.filter((c) => c.id == id)[0];

  return (
    <CercleForm
      cercle={cercle}
      onFormSubmit={updateMutation.mutate}
      onDeleteSubmit={deleteMutation.mutate}
      title="Update Cercle"
    />
  );
}
