import { useMutation } from "react-query";

import CercleForm from "../components/CercleForm";
import LeaderboardService from "../services/leaderboard";

import { useNavigate } from "react-router-dom";
import { Cercle } from "../services/types";
import { toast } from "react-toastify";

export default function CercleCreate() {
  let navigate = useNavigate();

  const createMutation = useMutation(
    async (cercle: Cercle): Promise<void> => {
      await LeaderboardService.createCercle(cercle);
    },
    {
      onSuccess: async () => {
        toast.success("Cercle Created");
        navigate(`/admin/cercles`);
      },
      onError: async (err: Error) => {
        toast.success("Error");
        console.log(err);
      },
    }
  );

  return <CercleForm onFormSubmit={createMutation.mutate} title="Add Cercle" />;
}
