import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router";
import LeaderboardService from "../services/leaderboard";
import { useNavigate } from "react-router-dom";
import AdminScoresTable from "../components/AdminScoresTable";
import { LeaderboardRow, Cercle, RemoveScore } from "../services/types";
import AddScoreForm from "../components/AddScoreForm";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";

export default function UpdateLeaderboardScores() {
  let { id } = useParams<"id">();
  const queryClient = useQueryClient();
  const { data: rows, isLoading: isLoading } = useQuery<
    LeaderboardRow[],
    Error
  >(["leaderboard", id], async () => {
    return await LeaderboardService.leaderboardById(Number(id));
  });

  const { data: cercles, isLoading: isLoadingCercle } = useQuery<
    Cercle[],
    Error
  >("cercles", async () => {
    return await LeaderboardService.findAllCerles();
  });

  const deleteMutation = useMutation(
    async (cercle_id: number): Promise<void> => {
      await LeaderboardService.deleteCercleFromLeaderboard({
        cercle_id: cercle_id,
        leaderboard_id: Number(id),
      });
    },
    {
      onSuccess: async () => {
        toast.success("Score deleted");
      },
      onError: async (err: Error) => {
        console.log(err);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["leaderboard", id]);
      },
    }
  );

  if (
    isLoading ||
    isLoadingCercle ||
    rows === undefined ||
    cercles === undefined
  ) {
    return <Spinner />;
  }

  return (
    <div className="">
      <AdminScoresTable rows={rows} onDeleteSubmit={deleteMutation.mutate} />
      <AddScoreForm cercles={cercles} leaderboard_id={Number(id)} />
    </div>
  );
}
