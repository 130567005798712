import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginForm from "../components/LoginForm";
import LeaderboardService from "../services/leaderboard";
import { LoginRequest } from "../services/types";

export default function Login() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const loginUser = useMutation(
    async (login: LoginRequest) => {
      return await LeaderboardService.authLogin(login);
    },
    {
      onSuccess: async (data) => {
        localStorage.setItem("token", data.access_token);
        queryClient.invalidateQueries("me");
        navigate(`/admin`);
      },
      onError: async () => {
        toast.error("Login failed.");
      },
    }
  );

  return <LoginForm onFormSubmit={loginUser.mutate} />;
}
