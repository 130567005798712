import { useForm } from "react-hook-form";

import LeaderboardService from "../services/leaderboard";
import { useMutation, useQueryClient } from "react-query";
import { AddScore, Cercle } from "../services/types";
import { toast } from "react-toastify";

type FormProps = {
  leaderboard_id: number;
  cercles: Cercle[];
};

type FormValues = {
  amount: number;
  cercle_id: number;
  leaderboard_id: number;
};

export default function AddScoreForm({ cercles, leaderboard_id }: FormProps) {
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      amount: 0,
      leaderboard_id: leaderboard_id,
    },
  });

  const createMutation = useMutation(
    async (addScore: AddScore): Promise<void> => {
      await LeaderboardService.addScore(addScore);
    },
    {
      onSuccess: () => {
        toast.success("Score added");
      },
      onError: async (err: Error) => {
        toast.error("Error");
        console.log(err);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["leaderboard", String(leaderboard_id)]);
      },
    }
  );

  const onSubmit = (addScore: AddScore) => {
    createMutation.mutate(addScore);
  };
  return (
    <form
      className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset className="mt-6 bg-white">
        <legend className="block text-sm font-medium text-gray-700">
          Change Score
        </legend>
        <div className="mt-1 rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="cercle" className="sr-only">
              Cercle
            </label>
            <select
              id="cercle"
              {...register("cercle_id")}
              className="focus:ring-blue-500 focus:border-blue-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
            >
              {cercles?.map(({ short_name, id }) => (
                <option value={id} key={id}>
                  {short_name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="amount" className="sr-only">
              Score
            </label>
            <input
              required
              type="number"
              id="amount"
              {...register("amount")}
              className="focus:ring-blue-500 focus:border-blue-500 relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
              placeholder="10"
            />
          </div>
        </div>
      </fieldset>
      <button
        type="submit"
        className="mt-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Save Score
      </button>
    </form>
  );
}
