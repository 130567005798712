import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import LeaderboardService from "../services/leaderboard";
import {  User } from "../services/types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserForm from "../components/UserForm";
import Spinner from "../components/Spinner";

export default function UserUpdate() {
  let navigate = useNavigate();
  let { id } = useParams<"id">();

  const updateMutation = useMutation(
    async (user: User): Promise<void> => {
      user.password = (user.password == "") ? undefined : user.password;
      await LeaderboardService.updateUser(String(id), user);
    },
    {
      onSuccess: async () => {
        toast.success("User Updated");
        navigate(`/admin/users`);
      },
      onError: async (err: Error) => {
        toast.error("Error");
        console.log(err);
      },
    }
  );

  const { data: users, isLoading: isLoading } = useQuery<User[], Error>(
    "users",
    async () => {
      return await LeaderboardService.findAllUsers();
    }
  );

  if (isLoading || users === undefined) {
    return <Spinner />;
  }

  const user = users.filter((c) => c.id == id)[0];

  return (
    <UserForm
      user={user}
      onFormSubmit={updateMutation.mutate}
    />
  );
}
