import { useMutation } from "react-query";
import LeaderboardForm from "../components/LeaderboardForm";
import LeaderboardService from "../services/leaderboard";

import { useNavigate } from "react-router-dom";
import { Leaderboard } from "../services/types";
import { toast } from "react-toastify";

export default function LeaderboardCreate() {
  let navigate = useNavigate();

  const createMutation = useMutation(
    async (leaderboard: Leaderboard): Promise<void> => {
      await LeaderboardService.createLeaderboard(leaderboard);
    },
    {
      onSuccess: async () => {
        toast.success("Leaderboard created");
        navigate(`/admin/leaderboards`);
      },
      onError: async (err: Error) => {
        toast.error("Error");
        console.log(err);
      },
    }
  );

  return (
    <LeaderboardForm
      onFormSubmit={createMutation.mutate}
      title="Add leaderboard"
    />
  );
}
