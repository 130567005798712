import { useState } from "react";
import { LeaderboardRow } from "../services/types";
import ConfirmDialog from "./ConfirmDialog";

interface AdminScoreTableProps {
  rows: LeaderboardRow[];
  onDeleteSubmit: Function;
}

export default function AdminScoreTable({
  rows,
  onDeleteSubmit,
}: AdminScoreTableProps) {
  const [confirm, setConfirm] = useState(false);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Short Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-normal text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Score
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">DELETE</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows?.map(({ cercle, amount }, index) => (
                      <tr
                        key={cercle.id}
                        className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {cercle.short_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {cercle.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {amount}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <>
                            <button
                              type="button"
                              onClick={() => setConfirm(!confirm)}
                              className="mr-3 bg-red-700 py-2 px-4 border border-red-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                              Delete
                            </button>
                            <ConfirmDialog
                              title="Delete Score"
                              isOpen={confirm}
                              closeModal={() => setConfirm(false)}
                              onConfirm={() => onDeleteSubmit(cercle.id)}
                            >
                              Are you sure you want to delete this score? All of
                              your data will be permanently removed. This action
                              cannot be undone.
                            </ConfirmDialog>
                          </>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
