import LeaderTable from "../components/LeaderTable";
import background from "./04.svg";
function Leaderboard() {
  return (
    <div
      style={{
        background: `url(${background}), linear-gradient(
          to bottom,
          rgba(243,146,0,1),
          rgba(255,195,0,1)
        )`,
      }}
      className="flex min-h-screen bg-repeat"
    >
      <div className="mx-auto my-10">
        <div
          className="font-xmas 2xl:text-9xl text-6xl text-center text-white px-8 pb-12"
          style={{ textShadow: "0px 0px 10px rgba(0,0,0,0.5)" }}
        >
          Tournoi de Noël
        </div>
        <LeaderTable />
      </div>
    </div>
  );
}

export default Leaderboard;
