import { useQuery } from "react-query";
import LeaderboardService from "../services/leaderboard";
import { LeaderboardRow } from "../services/types";

export default function LeaderTable() {
  const { data: rows, isLoading: isLoading } = useQuery<
    LeaderboardRow[],
    Error
  >(
    "leaderboard",
    async () => {
      return await LeaderboardService.leaderboardById(1);
    },
    {
      // Refetch the data every 5 seconds
      refetchInterval: 5000,
    }
  );

  if (isLoading || rows === undefined) {
    return null;
  }

  let max = 0;

  if (rows.length > 0) {
    rows.sort((a, b) =>
      a.amount < b.amount ? 1 : b.amount < a.amount ? -1 : 0
    );
    max = rows[0].amount;
  }
  const top10 = rows.slice(0, 10);
  const percentage = (max: number, amount: number) => {
    return (amount / max) * 95;
  };

  const logoURL = (logoURL: string | undefined | null) => {
    if (logoURL == undefined || logoURL == null || logoURL == "") {
      return process.env.PUBLIC_URL + "/acel.png";
    }
    return logoURL;
  };

  return (
    <div className="bg-white shadow-2xl rounded-sm">
      <ul role="list" className="divide-y divide-gray-200">
        {top10?.map((row, index) => (
          <li key={row.cercle.id}>
            <div className="block hover:bg-gray-100">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div>
                    <p className="text-4xl text-gray-700 font-semibold w-12">
                      {index + 1}
                    </p>
                  </div>
                  <div className="flex-shrink-0 pr-4">
                    <img
                      className="h-24 w-24 object-contain"
                      src={logoURL(row.cercle.logo)}
                      alt=""
                    />
                  </div>
                  <div className="min-w-0 flex-1 px-4">
                    <div>
                      <p className="text-xl font-semibold text-gray-700">
                        {row.cercle.short_name}
                        {": " + row.amount + " Drinks"}
                      </p>
                    </div>
                    <div className="block">
                      <div>
                        <p className="text-base text-gray-900 hidden md:block">
                          {row.cercle.name}
                        </p>
                      </div>
                    </div>
                    <div className="block">
                      <div className="w-full bg-gray-300 mt-2 rounded-full border-4 border-gray-300 shadow-sm">
                        <div
                          className="bg-gradient-to-t from-yellow-600 to-yellow-400 text-xs leading-none py-3 text-center rounded-full"
                          style={{
                            width: `${percentage(max, row.amount)}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
